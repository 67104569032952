<template>
  <div class="stepper margin-horizontal-ml">
    <div :style="getProgressStyle" class="progress"></div>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  computed: {
    getProgressStyle() {
      const width = `${(this.currentStep / this.totalSteps) * 100}%`;
      return `width: ${width}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.stepper {
  height: 5px;
  background-color: $riseup_gray_0;
  border-radius: 30px;
}

.progress {
  height: 100%;
  background-color: $riseup_blue;
  transition: width 0.3s;
  border-radius: 0 30px 30px 0;
}
</style>
