<template>
  <div class="free-month-before-churn-popup ri-font-18">
    <colored-popup :overlayImage="require('@/assets/pink-flower-card.svg')" :close-action="close" :darkText="false">
      <template v-slot:top-content>
        <div class="header-text">
          <div class="margin-top-s">אפשר להציע לך לחשוב על זה שוב?</div>
          <div class="ri-large-headline">חודש בחינם עלינו</div>
        </div>
      </template>
      <template v-slot:bottom-content>
        <div class="margin-bottom-m">
          אנחנו מבינים שבתקופה של חוסר יציבות לא תמיד יש פנאי להתעסק בתזרים.
          אנחנו כאן כדי לייצר תחושת בטחון כלכלי למרות אי הוודאות שמקיפה אותנו.
        </div>
        <span>לכן רצינו להציע לך</span>
        <span class="ri-font-weight-bold">את החודש הבא בחינם,</span>
        <span>החיוב הבא יהיה בעוד {{ daysUntilPayment }} ימים.</span>
        <riseup-button class="margin-top-xl"
                       title="לקבל חודש בחינם"
                       :action="applyBenefit"
                       :loading="loading"
                       size="slim"
                       bold/>

        <div class="cancel-subscription-btn" @click="continueToChurn"> המשך להפסקת מינוי</div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import benefitsApi from '@/api/BenefitsApi';

const LAST_CHANCE_FREE_MONTH_PROMOTION = 'churn-survey-last-chance-1-month-free-months';

export default {
  name: 'MonthOpeningExperienceInsightPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    dontChurn: {
      type: Function,
      required: true,
    },
    continueToChurn: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('ChurnLastChance_FreeMonthBeforeChurnPopup_Shown', { daysUntilPayment: this.daysUntilPayment });
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('subscription', ['nextPaymentDate']),
    ...mapState('genericError', ['ERROR_TYPES']),
    daysUntilPayment() {
      return moment(this.nextPaymentDate).add(1, 'month').diff(moment(), 'days');
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('genericError', ['setErrorType']),
    close() {
      Segment.trackUserInteraction('ChurnLastChance_FreeMonthBeforeChurnPopup_CloseClicked');
      this.continueToChurn();
    },
    churn() {
      Segment.trackUserInteraction('ChurnLastChance_FreeMonthBeforeChurnPopup_ContinueToChurnClicked');
      this.continueToChurn();
    },
    async createAndApplyBenefit(retry = 0) {
      const { status } = await benefitsApi.createAndApplyBenefit(LAST_CHANCE_FREE_MONTH_PROMOTION);
      if (status === 'SUCCESS') {
        return undefined;
      }

      if (retry < 2) {
        return this.createAndApplyBenefit(retry + 1);
      }
      Segment.trackUserInteraction('ChurnSurvey_FreeMonthBeforeChurnPopup_FailedToApply');
      await this.setErrorType(this.ERROR_TYPES.SERVER_ERROR);
      return undefined;
    },
    async applyBenefit() {
      Segment.trackUserInteraction('ChurnSurvey_FreeMonthBeforeChurnPopup_ApplyBenefitClicked');
      this.loading = true;
      await this.createAndApplyBenefit();
      this.loading = false;
      this.dontChurn();
    },
  },
};
</script>

<style scoped lang="scss">
.free-month-before-churn-popup {
  text-align: right;
  line-height: 24px;

  .header-text {
    color: white;
  }

  .cancel-subscription-btn {
    width: 100%;
    text-align: center;
    padding: 24px 24px 8px 24px;
    cursor: pointer;
  }
}
</style>
