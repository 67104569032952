<template>
  <div class="cashflow-start-day-settings">
    <back-navigation-header :on-back="() => back(this.pageName)" :show-bottom-border="false" />
    <Stepper :current-step="currentStep" :total-steps="totalSteps"/>
    <div v-if="currentStep===1" class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="'מה הולך להשתנות?'"
                                      :image-name="`calendar-cashflow/current-cashflow`" :selected-start-day="selectedStartDay"
                                      :show-days="true" :title="'אז נתחיל מאיך שהתזרים בנוי היום'">
        <template v-slot:explanation>
          <span>התזרים הנוכחי שלך התחיל ב־</span><span class="ri-font-weight-bold">{{ currentCashflowStart }}</span>
          <span>וצפוי להסתיים ב־</span><span class="ri-font-weight-bold">{{ currentCashflowEnd }}.</span>
        </template>
      </cashflow-start-day-walkthrough>
    </div>
    <div v-else-if="currentStep===2 " class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="'ומה עם ההוצאות וההכנסות?'"
                                      :image-name="`calendar-cashflow/${variant}-cashflow-after-change`" :selected-start-day="selectedStartDay"
                                      :show-days="true" :title="'ככה ייראה התזרים לאחר השינוי'">
        <template v-slot:explanation>
          <span>במבנה התזרים החדש, יום ההתחלה הוא ה־</span><span :class="{ 'ri-font-weight-bold': variant===END_OF_MONTH }">
          {{ cashflowStartDate }}</span>
          <span>וצפוי להסתיים ב־</span><span :class="{ 'ri-font-weight-bold': variant===END_OF_MONTH }">
          {{ cashflowEndDate }}.</span>
          <span v-if="variant===START_OF_MONTH" class="ri-font-weight-bold">כלומר הוא תזרים {{ monthNameAfterChange }}.</span>
        </template>
      </cashflow-start-day-walkthrough>
    </div>
    <div v-else-if="currentStep===3 " class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="'הבנתי, תודה'"
                                      :image-name="`calendar-cashflow/${variant}-incomes-and-expenses`" :selected-start-day="selectedStartDay"
                                      :title="`התזרים יציג את ההוצאות וההכנסות שקרו ויקרו בחודש ${monthNameAfterChange}`">
        <template v-slot:explanation/>
      </cashflow-start-day-walkthrough>
    </div>
  </div>
</template>
<script>
import CashflowStartDayWalkthrough
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/CashflowStartDayWalkthrough.vue';
import CashflowStartDaySettingsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/CashflowStartDaySettingsMixin.vue';
import Stepper from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/Stepper.vue';
import BackNavigationHeader from '@/base-components/app-header/BackNavigationHeader.vue';

export default {
  name: 'ChangeToCalendarCashflow',
  components: { BackNavigationHeader, Stepper, CashflowStartDayWalkthrough },
  mixins: [CashflowStartDaySettingsMixin],
  props: {
    selectedStartDay: { type: Number, required: true },
    onDone: { type: Function, required: true },
    onCancel: { type: Function, required: true },
  },
  data() {
    return {
      currentStep: 1,
    };
  },
  created() {
    this.updateSelectedStartDay(this.selectedStartDay);
  },
  computed: {
    totalSteps() {
      return 3;
    },
    pagesNames() {
      return {
        1: 'CashflowStartDaySettings_Current',
        2: 'CashflowStartDaySettings_AfterChange',
        3: 'CashflowStartDaySettings_Overview',
      };
    },
    pageName() {
      return this.pagesNames[this.currentStep];
    },
  },
  methods: {
    _nextStep() {
      this.nextStep(this.pageName);
    },
    _cancel() {
      this.onCancel(this.pageName);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.cashflow-start-day-settings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.months {
  justify-content: space-between;
}

.dots {
  height: 36px;
  margin-top: $ri-spacing-xxs;
  margin-bottom: $ri-spacing-xxs;
}

</style>
