<template>
  <div class="churn-step-3">
    <div class="title">סגירת חשבון</div>
    <div class="text">
      בשביל לאשר את התהליך של מחיקת כל הפרטים שלך מהשירות,
      <span class="bold">צריך להקליד את המילה ״מחיקה״</span>
      ולאשר
    </div>
    <text-input class="text-input"
                :onInput="onInput"
                :value="validationWord"
                :error="!validationWordIsRight"
                :label-text="validationFailure"/>

    <riseup-button @click="approve"
                   :disabled="!validationWordIsRight || clicked"
                   title="למחוק את החשבון"
                   :loading="clicked"
                   color="red" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'ChurnStep3',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    TextInput: BaseUI.TextInput,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      validationWord: '',
      clicked: false,
    };
  },
  computed: {
    validationWordIsRight() {
      return this.validationWord === 'מחיקה';
    },
    longValidationWordWasTyped() {
      return this.validationWord.length >= 5;
    },
    validationFailure() {
      return !this.validationWordIsRight && this.longValidationWordWasTyped ? 'כדאי לבדוק אם הקלדת נכון' : null;
    },
  },
  methods: {
    onInput(value) {
      this.validationWord = value;
    },
    approve() {
      if (!this.clicked) {
        this.clicked = true;
        Segment.trackUserInteraction('ChurnStep3_ApproveClicked');
        this.nextStep();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.churn-step-3 {
  .title {
    margin-top: 8px;
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: bold;
  }

  .text {
    margin-bottom: 45px;
    font-size: 18px;
    line-height: 24px;
    .bold {
      font-weight: bold;
    }
  }

  .text-input {
    margin-bottom: 24px;
  }
}
</style>
