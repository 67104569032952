<template>
  <last-chance-open-banking v-if="flavor === surveyFlavors.OPEN_BANKING"
                            @churn="approve" @dont-churn="goToCredentials" :continue-button-text="continueButtonText" />
  <last-chance-ten-to-ten v-else-if="flavor === surveyFlavors.TEN_TO_TEN"
                          @churn="approve" @dont-churn="goToChangeCfStartDay" :continue-button-text="continueButtonText"/>
  <last-chance-yearly-subscription
      v-else-if="flavor === surveyFlavors.YEARLY_SUBSCRIPTION"
      @churn="approve" @dont-churn="goToYearlySubscriptionPage" :continue-button-text="continueButtonText"/>
  <last-chance-free-month-before-churn
      v-else-if="flavor === surveyFlavors.FREE_MONTH_BEFORE_CHURN"
      @churn="approve" @dont-churn="goToFreeMonthBeforeChurn" :continue-button-text="continueButtonText"/>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Segment from '@/Segment';
import LastChanceTenToTen from '@/pages/responsive-pages/authenticated/churn/churn-last-chance/LastChanceTenToTen';
import LastChanceOpenBanking
  from '@/pages/responsive-pages/authenticated/churn/churn-last-chance/LastChanceOpenBanking';
import LastChanceYearlySubscription from '@/pages/responsive-pages/authenticated/churn/churn-last-chance/LastChanceYearlySubscription.vue';
import LastChanceFreeMonthBeforeChurn from '@/pages/responsive-pages/authenticated/churn/churn-last-chance/LastChanceFreeMonthBeforeChurn.vue';

export default {
  name: 'ChurnLastChance',
  components: {
    LastChanceOpenBanking,
    LastChanceTenToTen,
    LastChanceYearlySubscription,
    LastChanceFreeMonthBeforeChurn,
  },
  props: {
    continueButtonText: { type: String },
  },
  created() {
    Segment.trackUserGot('ChurnLastChance_Shown', { flavor: this.flavor });
  },
  computed: {
    ...mapState('churnSurvey', ['flavor']),
    ...mapGetters('churnSurvey', ['surveyFlavors']),
  },
  methods: {
    goToCredentials() {
      Segment.trackUserGot('ChurnLastChance_ButtonClicked_GoToCreds');
      this.$emit('go-to-creds');
    },
    goToChangeCfStartDay() {
      Segment.trackUserGot('ChurnLastChance_ButtonClicked_ChangeCfStartDay');
      this.$emit('go-to-change-cf-start-day');
    },
    goToYearlySubscriptionPage() {
      Segment.trackUserGot('ChurnLastChance_ButtonClicked_YearlySubscription');
      this.$emit('go-to-yearly-subscription');
    },
    goToFreeMonthBeforeChurn() {
      Segment.trackUserGot('ChurnLastChance_ButtonClicked_FreeMonthBeforeChurn');
      this.$emit('go-to-free-month-before-churn');
    },
    approve() {
      Segment.trackUserGot('ChurnLastChance_ButtonClicked_ContinueToChurn', { flavor: this.flavor });
      this.$emit('done');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.churn-last-chance {
  .bold {
    font-weight: bold;
  }

  .image {
    display: flex;
    margin: 0 auto 16px;
  }
}
</style>
