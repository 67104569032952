<template>
  <div class="activation-survey">
    <BackNavigationHeader :onBack="onBack"/>
    <component :is="activeStepComponent"/>
  </div>
</template>

<script>
import Segment from '@/Segment';
import { mapState, mapMutations } from 'vuex';
import BackNavigationHeader from '@/base-components/app-header/BackNavigationHeader';
import { activationSurveyStepsConfig } from '@/pages/responsive-pages/authenticated/survey/activation-survey/consts';

export default {
  name: 'ActivationSurvey',
  components: {
    BackNavigationHeader,
  },
  created() {
    Segment.trackUserGot('ActivationSurveyEntered');
  },
  computed: {
    ...mapState('activationSurvey', ['activeStep']),
    activeStepComponent() {
      return activationSurveyStepsConfig[this.activeStep].component;
    },
  },
  methods: {
    ...mapMutations('activationSurvey', ['setStepBack']),
    onBack() {
      this.setStepBack();
    },
  },
};
</script>

<style lang="scss" scoped>
.activation-survey {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
