<template>
  <div class="churn-flow">
    <div class="app-header">
      <back-navigation-header :onBack="prevStep" :onClose="onClose"/>
    </div>
    <div class="content">
      <churn-survey-options-question
          v-if="step === 1"
          title="לפני שנפסיק את המינוי, נשמח לדעת מה היתה הבעיה כדי שנוכל להשתפר"
          next-step-text="אפשר להמשיך"
          :next-step="nextStep"
          :no-radio-selected="goImmediatelyToFreeText"
          :options="optionsForQ1"
          :question-number="1"/>
      <churn-survey-options-question
          v-else-if="step === 2"
          :title="titleForQ2"
          next-step-text="להפסקת מינוי"
          :next-step="nextStep"
          :options="optionsForQ2"
          :question-number="2"/>
      <churn-last-chance v-else-if="step === 3 && shouldShowLastChance"
                         :continue-button-text="continueButtonText"
                         @done="doneAnsweringClosedQuestions = true"
                         @go-to-creds="goToCreds"
                         @go-to-change-cf-start-day="goToChangeCfStartDay"
                         @go-to-yearly-subscription="goToYearlySubscription"
                         @go-to-free-month-before-churn="goToFreeMonthBeforeChurn"/>
      <churn-survey-free-text v-else-if="step === 3 && !shouldShowLastChance" :next-step="done"/>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import * as _ from 'lodash';
import moment from 'moment';
import router from '@/router';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import benefitsApi from '@/api/BenefitsApi';
import cashflowModelsApi from '@/api/CashflowModels';
import EventBus from '@/event-bus/event-bus';
import ChurnSurveyFreeText from '@/pages/responsive-pages/non-authenticated/churn/ChurnSurveyFreeText';
import ChurnSurveyOptionsQuestion from '@/pages/responsive-pages/non-authenticated/churn/ChurnSurveyOptionsQuestion';
import BackNavigationHeader from '@/base-components/app-header/BackNavigationHeader';
import OpenBankingMixin from '@/base-components/onboarding/openBanking/OpenBankingMixin';
import FreeMonthBeforeChurnReceivedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/FreeMonthBeforeChurnReceivedToaster.vue';
import ChurnLastChance from '../../authenticated/churn/churn-last-chance/ChurnLastChance';

const LAST_CHANCE_FREE_MONTH_PROMOTION = 'churn-survey-last-chance-1-month-free-months';
const CHURN_PREDICTED_THRESHOLD = 0.02;

const answersTree = [
  {
    answer: 'השירות לא מתאים לי בתקופה הנוכחית',
    titleForQ2: 'למה השירות לא מתאים לך בתקופה הנוכחית?',
    optionsForQ2: [
      'אין לי מספיק הכנסות לנהל',
      'התזרים שלי לא יציב בתקופה הזו',
      'אין לי פנאי לחשוב על זה',
    ],
  },
  {
    answer: 'השירות לא עזר לי',
    titleForQ2: 'למה השירות לא עזר לך?',
    optionsForQ2: [
      'השירות לא שיפר את המצב שלי',
      'לא הבנתי את התזרים',
      'לא השתמשתי בתזרים',
      'אני אסתדר בלי זה',
    ],
  }, {
    answer: 'התזרים לא שיקף נכון את המצב שלי',
    titleForQ2: 'למה התזרים לא היה נכון?',
    optionsForQ2: [
      'היו שגיאות במידע שהוצג',
      'אי אפשר לעדכן הוצאות והכנסות ידנית',
      'לא מתאים לי תזרים מה-1 בחודש',
      'אין מספיק יכולות עריכה',
    ],
  }, {
    answer: 'לא היה לי נוח',
    titleForQ2: 'מה היה לא נוח?',
    optionsForQ2: [
      'אין אפליקציה',
      'הודעות הוואטסאפ הפריעו לי',
      'יש יותר מדי עדכוני סיסמאות',
      'דיוק התזרים דרש הרבה עבודה',
    ],
  }, {
    answer: 'השירות יקר מדי',
    titleForQ2: 'השירות יקר מדי',
    optionsForQ2: [
      'יש שירותים זולים יותר',
      'באפליקציה של הבנק יש שירות דומה',
      'לא שווה לי את המחיר',
      'אין לי כסף לשלם על זה כרגע',
    ],
  },
];

export default {
  name: 'ChurnSurveyFlow',
  components: {
    ChurnSurveyOptionsQuestion,
    ChurnSurveyFreeText,
    ChurnLastChance,
    BackNavigationHeader,
  },
  props: {
    onClose: {
      type: Function,
      require: true,
    },
    onBack: {
      type: Function,
      require: true,
    },
    continueButtonText: {
      type: String,
      default: 'למחוק את החשבון',
    },
    intent: {
      type: String,
    },
  },
  async created() {
    Segment.trackUserInteraction('StartSurveyClicked');
    await this.setIsEligibleForFreeMonth();
  },
  data() {
    return {
      step: 1,
      doneAnsweringClosedQuestions: false,
      noRadioSelected: false,
      isEligibleForFreeMonth: undefined,
    };
  },
  mixins: [OpenBankingMixin],
  computed: {
    ...mapState('session', ['customerId']),
    ...mapState('churnSurvey', ['answers', 'flavor']),
    ...mapState('subscription', ['nextPaymentDate']),
    ...mapState('credentials', ['missingBankCCAccountsAfterOBKMigration']),
    ...mapGetters('churnSurvey', ['surveyFlavors']),
    ...mapGetters('credentials', ['connectedCredentials']),
    ...mapGetters('featureFlags', ['enableChangeCashflowStartDay']),
    ...mapGetters('subscription', ['isYearlySubscription']),
    ...mapGetters('subscriptionState', ['inTrial']),
    optionsForQ1() {
      return _.map(answersTree, ({ answer }) => answer);
    },
    answerTreeForQ2() {
      return _.find(answersTree, ({ answer }) => answer === this.answers.q1Answer) || {};
    },
    titleForQ2() {
      return this.answerTreeForQ2.titleForQ2;
    },
    optionsForQ2() {
      return this.answerTreeForQ2.optionsForQ2;
    },
    hasNonObkCredentials() {
      return _.some(this.connectedCredentials, credentials => this.shouldShowObkConnectOption(credentials));
    },
    shouldShowLastChance() {
      return this.flavor && !this.doneAnsweringClosedQuestions && this.flavorMeetRules;
    },
    flavorMeetRules() {
      return this.flavorRules[this.flavor];
    },
    flavorRules() {
      return {
        [this.surveyFlavors.OPEN_BANKING]: this.hasNonObkCredentials,
        [this.surveyFlavors.TEN_TO_TEN]: this.enableChangeCashflowStartDay,
        [this.surveyFlavors.YEARLY_SUBSCRIPTION]: !this.isYearlySubscription,
        [this.surveyFlavors.FREE_MONTH_BEFORE_CHURN]: this.isEligibleForFreeMonth,
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal', 'closeAllModals', 'openModal']),
    async setIsEligibleForFreeMonth() {
      const [benefits, { modelScore: churnModelScore }] = await Promise.all([
        benefitsApi.getEligibleBenefits(),
        cashflowModelsApi.getChurnPrediction(),
      ]);

      const hasBenefit = _.some(benefits, benefit => benefit.promotion === LAST_CHANCE_FREE_MONTH_PROMOTION);
      const probableToChurn = !churnModelScore || churnModelScore >= CHURN_PREDICTED_THRESHOLD;

      this.isEligibleForFreeMonth = !hasBenefit && probableToChurn && !this.inTrial;

      DDLogs.log('FreeMonth before churn eligibility', {
        customerId: this.customerId,
        churnModelScore: String(churnModelScore),
        hasBenefit,
        inTrial: this.inTrial,
        isEligibleForFreeMonth: this.isEligibleForFreeMonth,
      });
    },
    nextStep() {
      this.step++;
    },
    prevStep() {
      Segment.trackUserInteraction('ChurnSurveyPrevStepClicked', {
        fromPage: this.step,
      });
      if (this.step === 1) {
        this.closeModal();
        this.onBack();
      } else if (this.step === 3 && this.doneAnsweringClosedQuestions) {
        this.doneAnsweringClosedQuestions = false;
      } else if (this.step === 3 && this.noRadioSelected) {
        this.step = 1;
        this.noRadioSelected = false;
      } else {
        this.step--;
      }
    },
    done() {
      this.doneAnsweringClosedQuestions = false;
      this.closeModal();
      this.onClose();
    },
    async goToCreds() {
      await router.push('credentials');
      this.closeAllModals();
    },
    async goToChangeCfStartDay() {
      await router.push('cashflow-start-day-settings');
      this.closeAllModals();
    },
    goToYearlySubscription() {
      this.closeAllModals();
      router.push({ path: '/subscription/update' });
    },
    async goToFreeMonthBeforeChurn() {
      this.closeAllModals();
      EventBus.$emit('open-toaster', {
        component: FreeMonthBeforeChurnReceivedToaster,
        props: {
          nextPaymentDate: moment(this.nextPaymentDate).add(1, 'month').format('D.M.YYYY'),
        },
      });
      await router.push('home');
    },
    goImmediatelyToFreeText() {
      this.noRadioSelected = true;
      this.step = 3;
    },
  },
};
</script>

<style lang="scss" scoped>
.churn-flow {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: start;
  overflow: auto;

  .app-header {
    flex-grow: 0;
  }

  .content {
    flex-grow: 1;
  }
}
</style>
