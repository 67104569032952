<template>
  <div class="churn-step-1">
    <div class="top-icon">
      <img class="churn-icon" :src="require('@/assets/churn/churn-icon.svg')">
    </div>
    <div class="title">{{ title }}</div>
    <div class="text">אנחנו פה בשבילך ונשמח לעזור לך בכל דרך לקבל ערך מהשירות שלנו</div>
    <riseup-button v-if="jitChurn" class="first-button chat-with-cx-button"
                   @click="chatWithCx"
                   title="לדבר עם הצוות"
                   :icon="require('@/assets/intercom_chat_icon.svg')"/>
    <separator class="margin-vertical-s" text="או"/>
    <div v-if="!hasAcceptedMoneyBackOffer" class="button-helper">להפסיק את שירות התזרים ולקבל החזר
כספי על החלק היחסי בחודש.</div>
    <riseup-button @click="approve"
                   :title="button"
                   color="red"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import Intercom from '@/Intercom';

const INTERCOM_MESSAGE = `הי,
חשבתי לעזוב את השירות. לפני שאני עושה את זה, רציתי לדבר אתכם על:
`;

export default {
  name: 'ChurnStep1',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Separator: BaseUI.Separator,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    variant: {
      type: String,
      default: 'churn',
    },
  },
  computed: {
    ...mapGetters('featureFlags', ['jitChurn']),
    ...mapGetters('subscriptionState', ['hasAcceptedMoneyBackOffer']),
    title() {
      return this.variant === 'cancelSubscription' ? 'רגע לפני שנפסיק את המינוי' : 'חבל לנו שהחלטת לעזוב';
    },
    button() {
      return this.variant === 'cancelSubscription' ? 'להפסיק את המנוי' : 'למחוק את החשבון';
    },
  },
  methods: {
    approve() {
      Segment.trackUserInteraction('ChurnStep1_ApproveClicked', { variant: this.variant });
      this.nextStep();
    },
    chatWithCx() {
      Segment.trackUserInteraction('ChurnStep1_ChatWithCxClicked', { variant: this.variant });
      this.close();
      Intercom.showNewMessage(INTERCOM_MESSAGE);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.churn-step-1 {
  text-align: center;

  .button-helper {
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    margin: 0 auto 16px;
    white-space: break-spaces;
  }

  .money-back-callout {
    margin-bottom: 18px;
    text-align: right;
  }

  .top-icon {
    margin-top: 26px;
    margin-bottom: 40px;

    .churn-icon {
      width: 100%;
    }
  }

  .title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .text {
    margin-bottom: 36px;
    font-size: 18px;
    line-height: 24px;
  }
}
</style>
