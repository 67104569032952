<template>
  <div class="cashflow-start-day-settings">
    <cashflow-start-day-selection v-if="!selectedStartDay" :on-confirm="setSelectedStartDay" class="cashflow-start-day-settings"/>
    <change-to-non-calendar-cashflow v-else-if="this.selectedStartDay!==1" :onCancel="cancel" :onDone="changeCashflowStartDay"
                                     :selected-start-day="selectedStartDay"/>
    <change-to-calendar-cashflow v-else-if="this.selectedStartDay===1" :onCancel="cancel" :onDone="changeCashflowStartDay"
                                 :selected-start-day="selectedStartDay"/>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import { updateCashflowStartDay } from '@/api/CashflowHomeApi';
import DDLogs from '@/DDLogs';
import LoadingPopup from '@/base-components/onboarding/LoadingPopup.vue';
import ChangeCashflowStartDaySuccessPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/ChangeCashflowStartDaySuccessPopup.vue';
import CashflowStartDaySelection
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/CashflowStartDaySelection.vue';
import ChangeToNonCalendarCashflow
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/ChangeToNonCalendarCashflow.vue';
import CashflowStartDaySettingsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/CashflowStartDaySettingsMixin.vue';
import ChangeToCalendarCashflow
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/ChangeToCalendarCashflow.vue';

export default {
  name: 'CashflowStartDaySettings',
  components: { ChangeToCalendarCashflow, ChangeToNonCalendarCashflow, CashflowStartDaySelection },
  mixins: [CashflowStartDaySettingsMixin],
  data() {
    return {
      selectedStartDay: null,
    };
  },
  computed: {
    ...mapState('websocket', ['socketMessage']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),

    setSelectedStartDay(selectedStartDay) {
      this.updateSelectedStartDay(selectedStartDay);
      this.nextStep('CashflowStartDaySelection');
    },
    async changeCashflowStartDay() {
      Segment.trackUserInteraction('CashflowStartDaySettings_Confirmed', { cashflowStartDay: this.cashflowStartDay });
      this.isLoading = true;
      this.openModal({
        component: LoadingPopup,
        props: {
          title: 'המערכת מעדכנת את התזרים בהתאם ליום ההתחלה שבחרת',
        },
      });
      await updateCashflowStartDay(this.selectedStartDay);
      setTimeout(() => {
        Segment.trackUserInteraction('CashflowStartDaySettings_VersionTimeout', { cashflowStartDay: this.cashflowStartDay });
        window.location.href = '/sr';
      }, 60 * 1000);
    },
    cancel(pageName) {
      Segment.trackUserInteraction(`${pageName}_CancelClicked`, {
        cashflowStartDay: this.selectedStartDay,
        variant: this.variant,
      });
      this.selectedStartDay = undefined;
    },
  },
  watch: {
    socketMessage(newValue, oldValue) {
      if (this.isLoading && newValue !== oldValue) {
        DDLogs.log('Done waiting for version in cashflow change start day page, moving to next page');
        this.openModal({
          component: ChangeCashflowStartDaySuccessPopup,
          popupAlignment: 'full-screen',
          props: {
            cashflowStartDay: this.selectedStartDay,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.cashflow-start-day-settings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

</style>
