<template>
  <div class="last-chance-open-banking margin-vertical-l margin-horizontal-ml">
    <div class="ri-body margin-bottom-xs">אפשר לשנות את דעתך?</div>
    <div class="ri-large-headline margin-bottom-sm">
      כבר לא צריך את הסיסמה שלך לבנק כדי להתחבר לרייזאפ!
    </div>
    <div class="ri-body">
      כל מה שצריך זה לעבור
      <span class="bold">לחיבור ישיר,</span>
      זה לוקח פחות מדקה ויותר אין צורך לתת סיסמאות ולעדכן אותן בשירות!
    </div>
    <div class="flex-row justify-content-center">
      <img :src="require('@/assets/great-success.png')" class="great-success-asset-in-full-screen" />
    </div>
    <riseup-button class="margin-top-l"
                   @click="dontChurn"
                   title="לעבור לחיבור ישיר"/>
    <separator class="margin-vertical-s" text="או"/>
    <riseup-button @click="churn"
                   :title="continueButtonText"
                   color="red"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'LastChanceOpenBanking',
  components: {
    Separator: BaseUI.Separator,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    continueButtonText: { type: String },
  },
  methods: {
    churn() {
      this.$emit('churn');
    },
    dontChurn() {
      this.$emit('dont-churn');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.last-chance-open-banking {
  .bold {
    font-weight: bold;
  }
}
</style>
