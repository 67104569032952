<template>
  <div></div>
</template>

<script>

import { mapActions } from 'vuex';
import FreeMonthBeforeChurnPopup from './popups/FreeMonthBeforeChurnPopup.vue';

export default {
  name: 'LastChanceFreeMonthBeforeChurn',
  components: {},
  props: {
    continueButtonText: { type: String },
  },
  created() {
    this.openModal({
      component: FreeMonthBeforeChurnPopup,
      popupAlignment: 'center',
      props: {
        dontChurn: this.dontChurn,
        continueToChurn: this.churn,
      },
    });
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    churn() {
      this.closeModal();
      this.$emit('churn');
    },
    dontChurn() {
      this.$emit('dont-churn');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
