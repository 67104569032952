<template>
  <div class="churn-survey-options-question">
    <div class="small-title">
      {{ questionNumber }}
      מתוך 2
    </div>
    <div class="title">{{ title }}</div>
    <div class="answers">
      <radio-select :options="radioOptions" :set-answer="setAnswer"/>
    </div>
    <riseup-button :action="doneAnswer"
                   :title="nextStepText"
                   size="slim" />
  </div>
</template>

<script>
import RadioSelect from '@/base-components/RadioSelect';
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';

export default {
  name: 'ChurnSurveyOptionsQuestion',
  components: {
    RadioSelect,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    questionNumber: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    nextStep: {
      type: Function,
      required: true,
    },
    nextStepText: {
      type: String,
      required: true,
    },
    noRadioSelected: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      answer: null,
    };
  },
  computed: {
    radioOptions() {
      return this.options.map(value => {
        return {
          value,
          label: value,
        };
      });
    },
  },
  methods: {
    ...mapActions('churnSurvey', ['setSurveyAnswer', 'reportToSegment']),
    setAnswer(answer) {
      this.answer = answer;
    },
    doneAnswer() {
      if (this.answer) {
        this.setSurveyAnswer({ questionName: `q${this.questionNumber}Answer`, answer: this.answer });
        this.reportToSegment(`ChurnSurveyQ${this.questionNumber}Answered`);
        this.nextStep();
      } else if (this.noRadioSelected) {
        this.noRadioSelected();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.churn-survey-options-question {
  padding: 40px 24px;

  .small-title {
    font-size: 16px;
    line-height: 24px;
    color: $riseup_blue;
  }

  .title {
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
  }

  .answers {
    margin: 30px 0;
  }
}
</style>
