<template>
  <div class="churn-step-2">
    <div class="title">מה יקרה למידע שלי אחרי המחיקה?</div>

    <div class="text">
      אחרי האישור שלך אנחנו
      <span class="bold">נמחק לצמיתות מהמערכת</span>
      את:
    </div>

    <div class="item">
      <div class="check"><img :src="require('@/assets/icons/check-light-blue.svg')"></div>
      <div class="text">
        <span class="bold">כל המידע הפיננסי</span>
        שהופיע בתזרים
      </div>
    </div>

    <div class="item">
      <div class="check"><img :src="require('@/assets/icons/check-light-blue.svg')"></div>
      <div class="text">
        <span class="bold">כל פרטי ההתחברות</span>
        לחשבונות הבנק ולכרטיסי האשראי, כולל הסיסמאות
      </div>
    </div>

    <div class="item last-item">
      <div class="check"><img :src="require('@/assets/icons/check-light-blue.svg')"></div>
      <div class="text">
        <span class="bold">מספרי הטלפון והשמות</span>
        של כל מי ששותף איתך בתזרים (אלא אם נרשמת למוצר אחר אצלנו)
      </div>
    </div>

    <riseup-button @click="approve"
                   title="למחוק את החשבון"
                   color="red"
                   size="slim"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'ChurnStep2',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
  },
  methods: {
    approve() {
      Segment.trackUserInteraction('ChurnStep2_ApproveClicked');
      this.nextStep();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.churn-step-2 {
  .title {
    margin-top: 8px;
    margin-bottom: 22px;
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
  }

  .text {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
    .bold {
      font-weight: bold;
    }
  }

  .item {
    .check {
      height: 32px;
      margin-bottom: 12px;
      img {
        height: 32px;
      }
    }

    &.last-item .text {
      margin-bottom: 40px;
    }
  }
}
</style>
