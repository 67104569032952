<template>
  <div class="cashflow-start-day-selection">
    <back-navigation-header :on-back="back"/>
    <div class="start-day-selection-container">

      <div class="ri-font-size-16 margin-vertical-l margin-right-l">
        בחירת יום תחילת התזרים
      </div>
      <div @click="setSelected(1)">

        <cashflow-start-day-card
          :cashflow-start-day="1"
          :first-explanation="'לכולם ולכולן! השיטה שעובדת לעשרות אלפי לקוחות'"
          :second-explanation="'דרך חדשה ופשוטה להסתכל על הכסף שלך: הכנסות מול הוצאות בכל חודש'"
          :selected="selectedCashflowStartDay === 1"
        />
      </div>
      <div @click="setSelected(10)">

        <cashflow-start-day-card
          :cashflow-start-day="10"
          :first-explanation="'למי שרוצה להתחיל לספור הוצאות ב־10 בחודש'"
          :second-explanation="'התזרים עובד עם משכורת שכבר נכנסה בפועל - גם אם היא הגיעה כמה ימים לפני ה־10 בחודש'"
          :selected="selectedCashflowStartDay === 10"
        />
      </div>
    </div>
    <div class="button-container margin-horizontal-ml margin-vertical-l ">
      <riseup-button :action="()=>onConfirm(this.selectedCashflowStartDay)"
                     :disabled="!selectedCashflowStartDay || cashflowStartDay === selectedCashflowStartDay"
                     :size="'slim'" :title="'לשנות את יום תחילת התזרים'" class="button">
      </riseup-button>
    </div>

  </div>

</template>

<script>

import Segment from '@/Segment';
import { mapActions, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import CashflowStartDayCard
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/CashflowStartDayCard.vue';
import BackNavigationHeader from '@/base-components/app-header/BackNavigationHeader.vue';

export default {
  name: 'CashflowStartDaySelection',
  components: { BackNavigationHeader, CashflowStartDayCard, RiseupButton: BaseUI.RiseupButton },
  created() {
    Segment.trackUserGot('CashflowStartDaySettings_entered', { cashflowStartDay: this.cashflowStartDay });
    this.selectedCashflowStartDay = this.cashflowStartDay;
  },
  props: {
    onConfirm: { type: Function },
  },
  data() {
    return {
      selectedCashflowStartDay: this.cashflowStartDay,
    };
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay']),
    cashflowStartDays() {
      return [{ value: 1, label: 'תזרים מה־1 בחודש', description: 'ועד היום האחרון של החודש' },
        { value: 10, label: 'תזרים מה־10 בחודש', description: 'ועד ה־9 בחודש הבא' }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    setSelected(selectedCashflowStartDay) {
      this.selectedCashflowStartDay = selectedCashflowStartDay;
    },
    back() {
      Segment.trackUserInteraction('CashflowStartDaySelection_BackClicked', { cashflowStartDay: this.cashflowStartDay });
      this.$router.back();
    },

  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.border-bottom {
  border-bottom: 1px solid $riseup-gray-0;
}

.button-container {
  margin-bottom: $ri-spacing-l;
  flex-grow: 0;
}

.cashflow-start-day-selection {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.start-day-selection-container {
  overflow: scroll;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
</style>
