<template>
  <div :class="{ selected }" class="cashflow-start-day-card margin-horizontal-ml margin-bottom-l">
    <div :class="selected ? 'title' : ''" class="margin-top-m margin-right-ml ri-medium-bold-headline">תזרים מה־{{ cashflowStartDay }} בחודש</div>
    <div class="margin-top-xs margin-right-ml ri-body margin-bottom-m">{{ secondTitle }}</div>
    <cashflow-start-day-explanation :explanation="firstExplanation" :title="'למי זה מתאים?'"/>
    <cashflow-start-day-explanation :explanation="secondExplanation" :title="'מה חשוב לדעת?'"/>
  </div>
</template>
<script>

import CashflowStartDayExplanation
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/CashflowStartDayExplanation.vue';

export default {
  name: 'cashflow-start-day-card',
  components: { CashflowStartDayExplanation },
  props: {
    selected: { type: Boolean, default: false, required: false },
    cashflowStartDay: { type: Number },
    firstExplanation: { type: String },
    secondExplanation: { type: String },
  },
  data() {
    return {};
  },
  computed: {
    secondTitle() {
      if (this.cashflowStartDay === 1) {
        return 'ועד היום האחרון של החודש';
      }
      return `ועד ה־${this.cashflowStartDay - 1} בחודש הבא`;
    },
  },
  methods: {},

};
</script>

<style lang="scss" scoped>

@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/typography';
@import '~@riseupil/base-ui/src/scss/border-radius';

.cashflow-start-day-card {
  position: relative;
  box-sizing: border-box;
  border: solid 1px $riseup_gray_0;
  border-radius: $card-border-radius;
  flex-shrink: 0;

  &.selected {
    border: solid 1px $riseup_blue;

    .radio-button {
      border: solid 6px $riseup_blue;
    }

    .title {
      color: $riseup_blue;
    }
  }
}

</style>
