<template>
  <toaster @close-toaster="closeToaster" class="toaster">
    <template v-slot:text>
      <div>
        <span>קיבלת חודש בחינם,</span>
        <span class="ri-font-weight-bold">נחזור לחייב אותך ב {{ nextPaymentDate }}</span>
      </div>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'FreeMonthBeforeChurnReceivedToaster',
  components: {
    Toaster,
  },
  props: {
    nextPaymentDate: { type: String, required: true },
  },
  methods: {
    closeToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
.toaster {
  line-height: 24px;
}
</style>
