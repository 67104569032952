<template>
  <div class="last-chance-yearly-subscription margin-vertical-l margin-horizontal-ml">
    <div class="ri-body margin-bottom-xs">לקבל יותר ולשלם פחות</div>
    <div class="ri-large-headline margin-bottom-sm">
      אפשר לעבור למינוי שנתי לרייזאפ ולחסוך {{ yearlySavingAgainstMonthly }} ₪
    </div>
    <div class="ri-body margin-bottom-l">
      מגיע לך הנחה של 20% במעבר למינוי שנתי בתשלום אחד חד פעמי. רוצה לשמוע עוד?
    </div>
    <img class="image" :src="require('@/assets/subscription/subscription-discount-10nis.png')"/>
    <riseup-button class="margin-top-l"
                   @click="dontChurn"
                   title="לפרטים נוספים"
                  />
    <separator class="margin-vertical-s" text="או"/>
    <riseup-button @click="churn"
                   :title="continueButtonText"
                   color="red"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapGetters } from 'vuex';

export default {
  name: 'LastChanceYearlySubscription',
  components: {
    Separator: BaseUI.Separator,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    continueButtonText: { type: String },
  },
  computed: {
    ...mapGetters('subscription', ['yearlySavingAgainstMonthly']),
  },
  methods: {
    churn() {
      this.$emit('churn');
    },
    dontChurn() {
      this.$emit('dont-churn');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.last-chance-yearly-subscription {
  .bold {
    font-weight: bold;
  }

  .image {
    display: flex;
    margin: 0 auto 16px;
  }
}
</style>
