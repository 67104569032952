<template>
  <div class="last-chance-ten-to-ten margin-vertical-l margin-horizontal-ml">
    <div class="ri-body margin-bottom-xs">אפשר לשנות את דעתך?</div>
    <div class="ri-large-headline margin-bottom-sm">
      אפשר לשנות את יום תחילת התזרים ל־10 בחודש!
    </div>
    <div class="ri-body margin-bottom-l">
      כמה קליקים והתזרים שלך יתחיל לעבוד מה־10 בחודש! גם חודשי העבר ישתנו בהתאם
    </div>
    <img class="image" :src="require('@/assets/cashflow-start-day-walkthrough/calendar.svg')"/>
    <riseup-button class="margin-top-l"
                   @click="dontChurn"
                   title="לעבור לתזרים החל מה-10"/>
    <separator class="margin-vertical-s" text="או"/>
    <riseup-button @click="churn"
                   :title="continueButtonText"
                   color="red"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'LastChanceTenToTen',
  components: {
    Separator: BaseUI.Separator,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    continueButtonText: { type: String },
  },
  methods: {
    churn() {
      this.$emit('churn');
    },
    dontChurn() {
      this.$emit('dont-churn');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.last-chance-ten-to-ten {
  .bold {
    font-weight: bold;
  }

  .image {
    display: flex;
    margin: 0 auto 16px;
  }
}
</style>
