<template>
  <full-screen-modal :buttons="buttons" :show-riseup-logo="true" class="connection-success padding-bottom-xxs">
    <template v-slot:content>
      <div class="success-content">
        <lottie-player autoplay src="/animations/celebration/success-with-confetti.json" class="success-lottie"/>
        <div class="title">
          מצויין, מעכשיו יום תחילת התזרים שלך הוא ה־{{ cashflowStartDay }} בחודש!
        </div>
        <div class="subtitle">
          תמיד אפשר לשנות בחזרה את יום תחילת התזרים וחודשי העבר ישתנו בהתאם.
        </div>
      </div>
    </template>
  </full-screen-modal>

</template>

<script>

import FullScreenModal from '@/base-components/FullScreenModal.vue';
import BaseUi from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'ChangeCashflowStartDaySuccessPopup',
  components: { FullScreenModal, LottiePlayer: BaseUi.LottiePlayer },
  created() {
    Segment.trackUserGot('CashflowStartDaySettings_SuccessShown', { cashflowStartDay: this.cashflowStartDay });
  },
  props: {
    cashflowStartDay: { type: Object, required: true },
  },
  computed: {
    buttons() {
      return [
        {
          title: 'לתזרים',
          action: async () => {
            Segment.trackUserInteraction('CashflowStartDaySettings_SuccessClicked', { cashflowStartDay: this.cashflowStartDay });
            window.location.href = '/sr';
          },
          size: 'slim',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.connection-success {
  .success-content {
    color: $riseup_black;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .success {
      margin: 35px 0 40px;
      width: 160px;
      height: 160px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      font-family: SimplerPro;
      margin-bottom: 15px;
    }

    .subtitle {
      font-family: SimplerPro;
      font-size: 16px;
    }

    .title, .subtitle {
      padding: 0 24px;
      line-height: 24px;
    }

    .success-lottie {
      height: 200px;
      margin-top: 10px;
    }
  }
}
</style>
