<template>
  <div class="cashflow-start-day-walkthrough flex-column margin-horizontal-ml">
    <span class="margin-top-ml margin-bottom-sm">שינוי יום תחילת התזרים</span>
    <span class="ri-large-headline margin-bottom-l">{{ title }}</span>
    <div v-if="imageName" class="image flex-column">
      <div class="flex-row incomes margin-right-l">
        <span v-if="showPreviousIncome">משכורת {{ monthNameAfterChange }}</span>
        <span v-if="showNextIncome">משכורת {{ nextMonthAfterChange }}</span>
      </div>
      <img :src="require(`@/assets/cashflow-start-day-walkthrough/${imageName}.svg`)"/>
      <div class="flex-row months">
        <span>{{ monthNameAfterChange }}</span>
        <span>{{ nextMonthAfterChange }}</span>
      </div>
    </div>
    <div class="ri-large-body margin-bottom-l">
      <slot name="explanation"></slot>
    </div>
    <div class="button">
      <RiseupButton :action="confirm" :size="'slim'" :title="confirmText" class="margin-bottom-ml"/>
      <RiseupButton :action="cancel" :size="'slim'" :title="'ביטול'" :variant="'secondary'"/>
    </div>
  </div>
</template>
<script>
import BaseUi from '@riseupil/base-ui';
import dateUtils from '@/utils/dates';
import { mapState } from 'vuex';
import { getCurrentCashflowMonth, getCashflowMonthFromMonthsAgo } from '@riseupil/common-utils';

export default {
  name: 'cashflow-start-day-walkthrough',
  components: { RiseupButton: BaseUi.RiseupButton },
  props: {
    selectedStartDay: { type: Number },
    title: { type: String },
    confirm: { type: Function },
    cancel: { type: Function },
    confirmText: { type: String },
    imageName: { type: String, default: null, required: false },
    showPreviousIncome: { type: Boolean, default: false, required: false },
    showNextIncome: { type: Boolean, default: false, required: false },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay']),
    monthNameAfterChange() {
      return dateUtils.getHebrewMonth(this.currentCashflowMonth);
    },
    nextMonthAfterChange() {
      return dateUtils.getHebrewMonth(this.nextCashflowMonth);
    },
    currentCashflowMonth() {
      return getCurrentCashflowMonth(this.selectedStartDay);
    },
    nextCashflowMonth() {
      return getCashflowMonthFromMonthsAgo(this.currentCashflowMonth, -1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';

.button {
  margin-top: auto;
  margin-bottom: $ri-spacing-l;
}

.cashflow-start-day-walkthrough {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.months {
  justify-content: space-around
}

.image {
  margin: 0 -24px $ri-spacing-l -24px;
}

.incomes {
  justify-content: space-between;
  margin-left: 100px;
}
</style>
