<template>
  <popup class="churn-popup" :close-action="closePopup" :back-action="shouldShowBackButton">
    <template v-slot:content>
      <churn-step1 :next-step="nextStep" :close="close" v-if="step === 1"/>
      <churn-step2 :next-step="nextStep" v-else-if="step === 2"/>
      <churn-step3 :next-step="churn" v-else-if="step === 3"/>
    </template>
    <template v-slot:footer>
      <div class="cancel" v-on:click="close">
        <span class="delete-text">ביטול</span>
      </div>
    </template>
  </popup>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import router from '@/router';
import Segment from '@/Segment';
import ChurnStep1 from './ChurnStep1.vue';
import ChurnStep2 from './ChurnStep2.vue';
import ChurnStep3 from './ChurnStep3.vue';
import ChurnSurveyFlow from '../../non-authenticated/churn/ChurnSurveyFlow.vue';

export default {
  name: 'ChurnPopup',
  components: {
    Popup: BaseUI.Popup,
    ChurnStep1,
    ChurnStep2,
    ChurnStep3,
  },
  created() {
    Segment.trackUserInteraction('ChurnPopup_Shown', { variant: 'churn' });
    this.saveChurnSurveyDetails();
    this.setCustomerIntent('churned');
    if (this.skipSurvey) {
      this.step = 2;
      this.churnSurveyStep = false;
    }
  },
  props: {
    memberInviteImmediateChurn: {
      type: Boolean,
      default: null,
    },
    skipSurvey: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const step = this.memberInviteImmediateChurn ? 2 : 1;
    return {
      step,
      churnSurveyStep: false,
    };
  },
  computed: {
    shouldShowBackButton() {
      return (this.step === 1 || (this.skipSurvey && this.step === 2)) ? null : this.goBack;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('churn', ['churnCustomer', 'churnThroughMemberInvite']),
    ...mapActions('churnSurvey', ['saveChurnSurveyDetails', 'setCustomerIntent']),
    closePopup() {
      Segment.trackUserInteraction('ChurnPopup_CloseClicked');
      this.close();
    },
    close() {
      this.$emit('close');
    },
    goBack() {
      if (this.churnSurveyStep) {
        this.churnSurveyStep = false;
      } else if (this.step > 1) {
        this.step--;
      }
    },
    nextStep() {
      if (this.step === 1 && !this.churnSurveyStep) {
        this.churnSurveyStep = true;
        this.openModal({
          component: ChurnSurveyFlow,
          popupAlignment: 'full-screen',
          props: {
            onClose: this.nextStep,
            onBack: this.goBack,
          },
        });
      } else {
        this.step++;
      }
    },
    async churn() {
      if (this.memberInviteImmediateChurn) {
        await this.churnThroughMemberInvite();
        this.close();
        this.$emit('success');
        return;
      }
      this.close();
      this.churnCustomer();
      await router.push({ path: '/goodbye' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.churn-popup.popup {
  ::v-deep .top .content {
    margin-bottom: 0;
  }

  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .delete-text {
      color: $riseup_black;
      font-size: 18px;
      font-weight: normal;
    }
  }
}
</style>
