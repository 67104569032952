<template>
  <riseup-header @close="onClose" :show-logo="showLogo" :show-bottom-border="showBottomBorder">
    <div v-if="showBack" class="back" :class="{ disabled }" v-on="disabled ? {} : { click: onBack }">
      <img class="back-triangle" src="/images/nav/left-arrow.svg"/>
      <span>חזרה</span>
    </div>
  </riseup-header>
</template>

<script>

import RiseupHeader from './RiseupHeader.vue';

export default {
  name: 'BackNavigationHeader',
  props: {
    onBack: {
      type: Function,
      required: false,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    onClose: {
      type: Function,
      required: false,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    showBottomBorder: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    RiseupHeader,
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .back {
    color: $riseup_black;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;

    &.disabled {
      opacity: 50%;
      cursor: default;
    }

    .back-triangle {
      width: 9.5px;
      height: 15px;
      opacity: 1;
      margin-left: 8px;
    }
  }

</style>
