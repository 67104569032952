<script>

import dateUtils from '@/utils/dates';
import { mapState } from 'vuex';
import { getCurrentCashflowMonth, getCashflowMonthFromMonthsAgo } from '@riseupil/common-utils';
import Segment from '@/Segment';

export const END_OF_MONTH = '10 to end of month';
export const START_OF_MONTH = '1 to 9';

export default {
  name: 'CashflowStartDaySettingsMixin',
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay']),
    variant() {
      return new Date().getDate() < this.selectedStartDay ? START_OF_MONTH : END_OF_MONTH;
    },
    cashflowStartDate() {
      return dateUtils.startOfMonthHebText(this.currentCashflowMonth, this.selectedStartDay);
    },
    cashflowEndDate() {
      return dateUtils.endOfMonthHebText(this.currentCashflowMonth, this.selectedStartDay);
    },
    currentCashflowStart() {
      return dateUtils.startOfMonthHebText(getCurrentCashflowMonth(this.cashflowStartDay), this.cashflowStartDay);
    },
    currentCashflowEnd() {
      return dateUtils.endOfMonthHebText(getCurrentCashflowMonth(this.cashflowStartDay), this.cashflowStartDay);
    },
    currentCashflowMonth() {
      return getCurrentCashflowMonth(this.selectedStartDay);
    },
    nextCashflowStartDate() {
      return dateUtils.startOfMonthHebText(this.nextCashflowMonth, this.selectedStartDay);
    },
    nextCashflowMonth() {
      return getCashflowMonthFromMonthsAgo(this.currentCashflowMonth, -1);
    },
    END_OF_MONTH() {
      return END_OF_MONTH;
    },
    START_OF_MONTH() {
      return START_OF_MONTH;
    },
    monthNameAfterChange() {
      return dateUtils.getHebrewMonth(this.currentCashflowMonth);
    },
    nextMonthAfterChange() {
      return dateUtils.getHebrewMonth(this.nextCashflowMonth);
    },
  },
  methods: {
    updateSelectedStartDay(selectedStartDay) {
      this.selectedStartDay = selectedStartDay;
    },
    nextStep(pageName) {
      Segment.trackUserInteraction(`${pageName}_NextClicked`, {
        cashflowStartDay: this.selectedStartDay,
        variant: this.variant,
      });
      if (this.currentStep === this.totalSteps) {
        this.onDone();
      } else {
        this.currentStep += 1;
      }
    },
    back(pageName) {
      Segment.trackUserInteraction(`${pageName}_BackClicked`, {
        cashflowStartDay: this.selectedStartDay,
        variant: this.variant,
      });
      if (this.currentStep === 0) {
        this.$router.back();
      } else {
        this.currentStep -= 1;
      }
      if (this.currentStep === 0) {
        this.onCancel();
      }
    },
  },
};

</script>
