<template>
  <div class="churn-survey-question-3">
    <div class="small-title">ודבר נוסף</div>
    <div class="title">יש עוד משהו שהיית רוצה שנדע?</div>
    <div class="answers">
      <text-area :autofocus="true" value="" :on-input="onInput" placeholder="איך היתה החוויה שלך בשירות?"/>
    </div>
    <riseup-button :action="doneAnswer"
                   title="להפסקת מינוי"
                   size="slim"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';

export default {
  name: 'ChurnSurveyQuestion3',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    TextArea: BaseUI.TextArea,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      answer: null,
    };
  },
  methods: {
    ...mapActions('churnSurvey', ['setSurveyAnswer', 'reportToSegment']),
    onInput(value) {
      this.answer = value;
    },
    doneAnswer() {
      this.setSurveyAnswer({ questionName: 'freeText', answer: this.answer || 'N/A' });
      this.reportToSegment('ChurnSurveyQ3Answered');
      this.nextStep();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.churn-survey-question-3 {
  padding: 40px 24px;

  .small-title {
    font-size: 16px;
    line-height: 24px;
    color: $riseup_blue;
  }

  .title {
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
  }

  .answers {
    margin: 30px 0;
    .text-area {
      height: 145px;
    }
  }
}
</style>
