<template>
  <div class="cashflow-start-day-explanation margin-bottom-ml margin-left-m">
    <img :src="require(`@/assets/icons/profile/secondary-4.svg`)" class="logo margin-horizontal-m margin-top-xxs"/>
    <div>
      <div class="ri-font-weight-bold ri-font-18 text">{{ title }}</div>
      <span class="ri-font-18 text">{{ explanation }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cashflow-start-day-explanation',
  components: {},

  props: {
    explanation: { type: String },
    title: { type: String },
  },
};
</script>
<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/typography";

.cashflow-start-day-explanation {
  display: flex;
}

.logo {
  width: 30px;
  height: 30px;
}

.text {
  line-height: $ri-font-size-24;
}
</style>
