<template>
  <div class="cashflow-start-day-settings">
    <back-navigation-header :on-back="()=>back(this.pageName)" :show-bottom-border="false" />
    <Stepper :current-step="currentStep" :total-steps="totalSteps"/>
    <div v-if="currentStep===1" class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="'מה הולך להשתנות?'"
                                      :image-name="`${variant}/current-cashflow`" :selected-start-day="selectedStartDay"
                                      :title="'אז נתחיל מהתזרים הנוכחי'">
        <template v-slot:explanation>
          <span>התזרים הנוכחי שלך התחיל ב־</span><span class="ri-font-weight-bold">{{ currentCashflowStart }}</span>
          <span>וצפוי להסתיים ב־</span><span class="ri-font-weight-bold">{{ currentCashflowEnd }}.</span>
        </template>
      </cashflow-start-day-walkthrough>
    </div>
    <div v-else-if="currentStep===2 " class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="'מה זה אומר לגבי ההוצאות?'"
                                      :image-name="`${variant}/cashflow-after-change`" :selected-start-day="selectedStartDay"
                                      :title="'ככה ייראה התזרים לאחר השינוי'">
        <template v-slot:explanation>
          <span>במבנה התזרים החדש, יום ההתחלה הוא ה־</span><span
          :class="{ 'ri-font-weight-bold': variant===END_OF_MONTH }">{{ cashflowStartDate }}</span>
          <span>וצפוי להסתיים ב־</span><span :class="{ 'ri-font-weight-bold': variant===END_OF_MONTH }">{{ cashflowEndDate }}.</span>
          <span v-if="variant===START_OF_MONTH" class="ri-font-weight-bold">כלומר הוא תזרים {{ monthNameAfterChange }}.</span>
        </template>
      </cashflow-start-day-walkthrough>
    </div>
    <div v-else-if="currentStep===3 " class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="'ומה עם ההכנסות?'"
                                      :image-name="`${variant}/expenses`" :selected-start-day="selectedStartDay" :title="'אלו ההוצאות שיוצגו בתזרים'">
        <template v-slot:explanation>
          <span>התזרים החדש יציג את ההוצאות שכבר נעשו </span><span
          class="ri-font-weight-bold">מה־{{cashflowStartDate}}</span><span> ואת אלו שיעשו </span><span
          class="ri-font-weight-bold">עד ה־{{ cashflowEndDate }}.</span>
        </template>
      </cashflow-start-day-walkthrough>
    </div>
    <div v-else-if="currentStep===4" class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="`ומה עם משכורת ${nextMonthAfterChange}?`"
                                      :image-name="`${variant}/incomes-1`" :selected-start-day="selectedStartDay"
                                      :show-previous-income="true" :title="'כל תזרים מתחיל עם משכורת שכבר נכנסה לבנק'">
        <template v-slot:explanation>
          <span>לכן, התזרים החדש שמתחיל ב־{{ cashflowStartDate }} יתבסס על משכורת שנכנסה כבר בתחילת {{ monthNameAfterChange }}</span><span
          v-if="variant===END_OF_MONTH" class="ri-font-weight-bold">, גם אם היא הגיעה כמה ימים לפני ה־{{ selectedStartDay }}</span><span>.</span>
        </template>
      </cashflow-start-day-walkthrough>
    </div>
    <div v-else-if="currentStep===5" class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="'למה זה טוב?'"
                                      :image-name="`${variant}/incomes-2`" :selected-start-day="selectedStartDay"
                                      :show-next-income="true" :show-previous-income="true"
                                      :title="`משכורת ${nextMonthAfterChange} תחכה לך בתזרים הבא`">
        <template v-slot:explanation>
          <span>התזרים הבא – שיתחיל ב־{{ nextCashflowStartDate }} – יתבסס על משכורת {{ nextMonthAfterChange }}.</span>
        </template>
      </cashflow-start-day-walkthrough>
    </div>
    <div v-else-if="currentStep===6" class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="'הבנתי, אפשר להמשיך'"
                                      :image-name="`${variant}/indent-explanation`" :selected-start-day="selectedStartDay"
                                      :title="'התזרים מתחיל עם משכורת בבנק, ואין הפתעות בסוף החודש'">
        <template v-slot:explanation>
          <span>כשההוצאות החודשיות הן מכסף שכבר נכנס לבנק, </span>
          <span class="ri-font-weight-bold">החלטות שוטפות מתקבלות מתוך ודאות</span>
          <span> ולא על  בסיס צפי של משכורת עתידית.</span>
        </template>
      </cashflow-start-day-walkthrough>
    </div>
    <div v-else-if="currentStep===7" class="cashflow-start-day-settings">
      <cashflow-start-day-walkthrough :cancel="_cancel" :confirm="_nextStep" :confirm-text="`יאללה, אני רוצה תזרים מה־${this.selectedStartDay}`"
                                      :selected-start-day="selectedStartDay"
                                      :title="'חשוב לדעת שהתזרים הוא לא עו״ש'">
        <template v-slot:explanation>
          <span>תזרים הוא עדיין שיטה שעוזרת לנו </span>
          <span class="ri-font-weight-bold">להסתכל אחרת על הכסף</span>
          <span> שלנו, והיא לא מספרת כמה כסף יש לנו בעו"ש או כמה יהיה לנו בסוף החודש.</span>
        </template>
      </cashflow-start-day-walkthrough>
    </div>
  </div>
</template>
<script>
import CashflowStartDayWalkthrough
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/CashflowStartDayWalkthrough.vue';
import CashflowStartDaySettingsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/CashflowStartDaySettingsMixin.vue';
import Stepper from '@/pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/Stepper.vue';
import BackNavigationHeader from '@/base-components/app-header/BackNavigationHeader.vue';

export default {
  name: 'ChangeToNonCalendarCashflow',
  components: { BackNavigationHeader, Stepper, CashflowStartDayWalkthrough },
  mixins: [CashflowStartDaySettingsMixin],
  props: {
    selectedStartDay: { type: Number, required: true },
    onDone: { type: Function, required: true },
    onCancel: { type: Function, required: true },
  },
  data() {
    return {
      currentStep: 1,
    };
  },
  created() {
    this.updateSelectedStartDay(this.selectedStartDay);
  },
  computed: {
    totalSteps() {
      return 7;
    },
    pagesNames() {
      return {
        1: 'CashflowStartDaySettings_Current',
        2: 'CashflowStartDaySettings_AfterChange',
        3: 'CashflowStartDaySettings_Expenses',
        4: 'CashflowStartDaySettings_PreviousIncome',
        5: 'CashflowStartDaySettings_ExistingIncome',
        6: 'CashflowStartDaySettings_IndentationExplanation',
        7: 'CashflowStartDaySettings_ImportantToKnow',
      };
    },
    pageName() {
      return this.pagesNames[this.currentStep];
    },
  },
  methods: {
    _nextStep() {
      this.nextStep(this.pageName);
    },
    _cancel() {
      this.onCancel(this.pageName);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.cashflow-start-day-settings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

</style>
